/*  1. Theme Overrides
    ========================================================================== */
body {
    @extend .sans-regular;
}

a {
    color: $color-brand-3;
}
h1,h2,h3,h4,h5 {
    color:  $color-brand-3;
}
.nav-wrapper {
    background-color: $color-brand-1;
}
.tagline--primary {
    color: $color-brand-3;
}
.tagline--primary, .tagline--secondary {
    @extend .serif-bold;
}
.sub-header {
    background-color: $color-brand-3;
}
.sub-header--title {
    color: white;
}
.sub-header--bread-crumbs {
    color: $font-color-1;
}
.sub-header--bread-crumbs a {
    color: $font-color-1;
}
.documents-list {
    list-style-image: url(../images/document.png);
}

/*  2. Uber menu overrides
    ========================================================================== */
.ubermenu-skin-none {
    background-color: $color-brand-1;
}
.ubermenu-skin-none .ubermenu-item-level-0.ubermenu-current-menu-ancestor>.ubermenu-target, .ubermenu-skin-none .ubermenu-item-level-0.ubermenu-current-menu-item>.ubermenu-target, .ubermenu-skin-none .ubermenu-item-level-0.ubermenu-current-menu-parent>.ubermenu-target {
    background-color: $color-brand-2;
}
.ubermenu-skin-none.ubermenu-vertical .ubermenu-item-level-0 > .ubermenu-target {
    background: $nav-hover-color;
}
.ubermenu-skin-none .ubermenu-item-level-0.ubermenu-current-menu-item > .ubermenu-target,
.ubermenu-skin-none .ubermenu-item-level-0.ubermenu-current-menu-parent > .ubermenu-target,
.ubermenu-skin-none .ubermenu-item-level-0.ubermenu-current-menu-ancestor > .ubermenu-target {
  background: $nav-hover-color;
}
.ubermenu-skin-none .ubermenu-item-level-0:hover > .ubermenu-target,
.ubermenu-skin-none .ubermenu-item-level-0.ubermenu-active > .ubermenu-target {
  background: $nav-hover-color;
}
.ubermenu-skin-none .ubermenu-submenu .ubermenu-target:hover,
.ubermenu-skin-none .ubermenu-submenu .ubermenu-active > .ubermenu-target {
  background: $nav-sub-menu-hover-color;
}
.ubermenu-skin-none .ubermenu-submenu .ubermenu-item-header > .ubermenu-target:hover {
    background: $nav-sub-menu-hover-color;
}
.ubermenu-skin-none .ubermenu-submenu .ubermenu-current-menu-item > .ubermenu-target {
    background: $nav-sub-menu-hover-color;
}
.ubermenu-skin-none .ubermenu-submenu.ubermenu-submenu-drop {
    background: $nav-primary-color;
}
.ubermenu-skin-none.ubermenu-responsive-toggle {
    background-color: $nav-primary-color;
}
.ubermenu-skin-none.ubermenu-responsive-toggle:hover {
    background-color: $nav-sub-menu-hover-color;
}

body.home.blog {
    display: flex;
    justify-content: space-between;
}

.homepage {
    flex-grow: 1;
}