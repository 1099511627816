/*  ==========================================================================
    ==========================================================================

    Main
    1. Overrides

    ==========================================================================
    ========================================================================== */
/*  ========================================================================== 
	2. Parent Imports
    ========================================================================== */
/*  ==========================================================================
    ==========================================================================

    Variables
    1. Typography
    2. Colours
    3. Breakpoints

    CSS FORMATTING
    1. Positioning
    2. Display & Box Model
    3. Text
    4. Other

    ==========================================================================
    ========================================================================== */
/*  ========================================================================== 
	1. Typography
	========================================================================== */
/*  Fallback Stack
	========================================================================== */
/*  Font Sizes
	========================================================================== */
/*  Line Heights
	========================================================================== */
/*  Other Sizes
	========================================================================== */
/*  ==========================================================================
	2. Colours 
	========================================================================== */
/*  Brand Colours 
	========================================================================== */
/*  Dark Colours
	========================================================================== */
/*  Light Colours
	========================================================================== */
/*  Text Colours
	========================================================================== */
/*  Background Colours
	========================================================================== */
/*  Font Colours
	========================================================================== */
/*  Anchor Colours
	========================================================================== */
/*  Border Colours
	========================================================================== */
/*  Form Colours
	========================================================================== */
/*  Nav Colours
	========================================================================== */
/*  Misc Colours
	========================================================================== */
/*  ==========================================================================
	3. Breakpoints 
	========================================================================== */
/*  Desktop
	========================================================================== */
/*  Mobile / Tablet
	========================================================================== */
/*  ==========================================================================
    ==========================================================================

    Typography
    1. Font Definitions
    2. General Fonts

    CSS FORMATTING
    1. Positioning
    2. Display & Box Model
    3. Text
    4. Other

    ==========================================================================
    ========================================================================== */
/*  ========================================================================== 
    1. Font Definitions
    ========================================================================== */
@import url("https://fonts.googleapis.com/css?family=Spectral");
@font-face {
  font-family: open_sansregular;
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/opensans-regular-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/opensans-regular-webfont.woff2") format("woff2"), url("../fonts/opensans-regular-webfont.woff") format("woff"), url("../fonts/opensans-regular-webfont.ttf") format("truetype"), url("../fonts/opensans-regular-webfont.svg#open_sansregular") format("svg"); }

@font-face {
  font-family: open_sansbold;
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/opensans-bold-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/opensans-bold-webfont.woff2") format("woff2"), url("../fonts/opensans-bold-webfont.woff") format("woff"), url("../fonts/opensans-bold-webfont.ttf") format("truetype"), url("../fonts/opensans-bold-webfont.svg#open_sansbold") format("svg"); }

@font-face {
  font-family: open_sansitalic;
  font-style: italic;
  font-weight: 700;
  src: url("../fonts/opensans-italic-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/opensans-italic-webfont.woff2") format("woff2"), url("../fonts/opensans-italic-webfont.woff") format("woff"), url("../fonts/opensans-italic-webfont.ttf") format("truetype"), url("../fonts/opensans-italic-webfont.svg#open_sansitalic") format("svg"); }

.serif-regular, body {
  font-family: 'Spectral', serif;
  font-weight: 400;
  font-style: normal; }

.serif-bold, .tagline--primary, .tagline--secondary {
  font-family: 'Spectral', serif;
  font-weight: 700;
  font-style: normal; }

/*  Open Sans Regular
    ========================================================================== */
.sans-regular, body {
  font-family: "open_sansregular", "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  font-weight: 400;
  font-style: normal; }

/*  Open Sans Italic
    ========================================================================== */
.sans-italic, em {
  font-family: "open_sansitalic", "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  font-weight: 400;
  font-style: italic; }

/*  Open Sans Bold
    ========================================================================== */
.sans-bold, strong,
p.bold {
  font-family: "open_sansbold", "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  font-weight: 700;
  font-style: normal; }

/*  ==========================================================================
    2. General Fonts 
    ========================================================================== */
/*  HTML & BODY
    ========================================================================== */
html {
  font-size: 62.5%; }

body {
  -ms-font-feature-settings: "kern", "liga", "frac", "pnum";
  font-feature-settings: "kern", "liga", "frac", "pnum";
  -webkit-font-smoothing: antialiased;
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.5; }

/*  Titles
    ========================================================================== */
h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.25; }

h1 {
  font-size: 37px;
  font-size: 3.7rem; }

h2 {
  font-size: 23px;
  font-size: 2.3rem; }

h3 {
  font-size: 18px;
  font-size: 1.8rem; }

h4 {
  font-size: 16px;
  font-size: 1.6rem; }

h5 {
  font-size: 16px;
  font-size: 1.6rem; }

h6 {
  font-size: 14px;
  font-size: 1.4rem; }

/*  Body Copy, Anchors etc
    ========================================================================== */
p {
  margin: 0 0 0.75rem;
  font-size: 16px;
  font-size: 1.6rem; }

a {
  color: #f5821f;
  text-decoration: none; }
  a:hover {
    color: #bf5d09; }
  a:active, a:focus {
    color: #bf5d09;
    outline: none; }

/*  ========================================================================== 
	2. Helpers
    ========================================================================== */
/*  Generic Colours 
	========================================================================== */
/*  Brand Colours 
	========================================================================== */
/*  Font Colors
    ========================================================================== */
/*  Dark Colours
	========================================================================== */
/*  UI Colors
    ========================================================================== */
/*  ========================================================================== 
	3. Overrides
    ========================================================================== */
/*  1. Theme Overrides
    ========================================================================== */
a {
  color: #3399cc; }

h1, h2, h3, h4, h5 {
  color: #3399cc; }

.nav-wrapper {
  background-color: #8dc634; }

.tagline--primary {
  color: #3399cc; }

.sub-header {
  background-color: #3399cc; }

.sub-header--title {
  color: white; }

.sub-header--bread-crumbs {
  color: #9ddfff; }

.sub-header--bread-crumbs a {
  color: #9ddfff; }

.documents-list {
  list-style-image: url(../images/document.png); }

/*  2. Uber menu overrides
    ========================================================================== */
.ubermenu-skin-none {
  background-color: #8dc634; }

.ubermenu-skin-none .ubermenu-item-level-0.ubermenu-current-menu-ancestor > .ubermenu-target, .ubermenu-skin-none .ubermenu-item-level-0.ubermenu-current-menu-item > .ubermenu-target, .ubermenu-skin-none .ubermenu-item-level-0.ubermenu-current-menu-parent > .ubermenu-target {
  background-color: #3c861e; }

.ubermenu-skin-none.ubermenu-vertical .ubermenu-item-level-0 > .ubermenu-target {
  background: #3c861e; }

.ubermenu-skin-none .ubermenu-item-level-0.ubermenu-current-menu-item > .ubermenu-target,
.ubermenu-skin-none .ubermenu-item-level-0.ubermenu-current-menu-parent > .ubermenu-target,
.ubermenu-skin-none .ubermenu-item-level-0.ubermenu-current-menu-ancestor > .ubermenu-target {
  background: #3c861e; }

.ubermenu-skin-none .ubermenu-item-level-0:hover > .ubermenu-target,
.ubermenu-skin-none .ubermenu-item-level-0.ubermenu-active > .ubermenu-target {
  background: #3c861e; }

.ubermenu-skin-none .ubermenu-submenu .ubermenu-target:hover,
.ubermenu-skin-none .ubermenu-submenu .ubermenu-active > .ubermenu-target {
  background: #75b335; }

.ubermenu-skin-none .ubermenu-submenu .ubermenu-item-header > .ubermenu-target:hover {
  background: #75b335; }

.ubermenu-skin-none .ubermenu-submenu .ubermenu-current-menu-item > .ubermenu-target {
  background: #75b335; }

.ubermenu-skin-none .ubermenu-submenu.ubermenu-submenu-drop {
  background: #8dc634; }

.ubermenu-skin-none.ubermenu-responsive-toggle {
  background-color: #8dc634; }

.ubermenu-skin-none.ubermenu-responsive-toggle:hover {
  background-color: #75b335; }

body.home.blog {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between; }

.homepage {
  -ms-flex-positive: 1;
      flex-grow: 1; }
